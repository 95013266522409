<template>
  <div>
    <div class="z-0 pt-48">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <div class="text-gray-300">
          <div class="">
            <div class="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2
                  class="text-3xl font-extrabold sm:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500 text-shadow-white"
                >
                  Frequently Asked Questions
                </h2>
                <p
                  class="mt-4 text-lg text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300"
                >
                  Here are some common questions we get from our customers.
                </p>
              </div>
              <div class="col-span-2 mt-10 lg:mt-0">
                <dl class="space-y-10">
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      What is Prompt?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      A prompt is a piece of text used to guide the generation
                      of new text by a language model.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      How does prompt work?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      A prompt works by providing an initial input to a language
                      model, which then generates new text based on the input.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      How to use a prompt?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      To use a prompt, choose a language model, create a
                      specific prompt, format the prompt appropriately, input
                      the prompt into the language model, evaluate the generated
                      text, and refine the prompt as necessary.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      How can I earn from the prompt?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      Prompts themselves do not generate revenue, but language
                      models and generated text can be used in various ways to
                      generate revenue through content creation, chatbots,
                      translation, writing tools, and other applications.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      What is PromptDue?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      PromptDue is a marketplace for everyone who is interested
                      to buy or sell their arts created from GPT-3, ChatGPT,
                      DALL-E, Midjourney, Stable Diffusion,
                      PromptBase,BlueWillow. You need not be an artist to create
                      prompt. It depends on imagination, creativity and ideas.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      What is a Prompt Engineer?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      A Prompt Engineer is a new kind of technician, skilled at
                      crafting the text prompts required for an AI model to
                      produce consistent outputs (e.g. images, text or code).
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      How do we split the rewards ?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      Prompt sellers will receive 80% on every prompt sold.
                      PromptDue will keep 20% on every prompt sold to run the
                      platform and provide support services.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      What is Prompt Engineering And Why It Matters To The AI
                      Revolution ?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      Prompt engineering is a natural language processing (NLP)
                      concept that involves discovering inputs that yield
                      desirable or useful results. Prompting is the equivalent
                      of telling the Genius in the magic lamp what to do. In
                      this case, the magic lamp is DALL-E, ready to generate any
                      image you wish for.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      How will I know how many sales I have made ?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      You can check from the account page there will be
                      transaction details mentioned on your wallet.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      How feature prompts determine ?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      Feature prompt determined from most likes prompt.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      How trending prompts determine ?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      Trending prompt determined from most views prompt.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      How popular prompts determine ?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      Popular prompt determined from most purchased prompt.
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      How to receive payment ?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      Payment will be credited to your account directly from
                      buyers
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      How long does it take to receive payment ?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      Instantly after buyers purchases
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      How long does it take to approve a prompt ?
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      Prompt will be approved within 24 hours
                    </dd>
                  </div>
                  <div>
                    <dt
                      class="text-lg leading-6 font-medium text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-200"
                    >
                      Trending prompt determined from most views prompt.
                    </dt>
                    <dd class="mt-2 text-base text-gray-400">
                      A prompt is a piece of text used to guide the generation
                      of new text by a language model.
                    </dd>
                  </div>

                  <h2
                    class="text-2xl font-extrabold sm:text-2xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500 text-shadow-white"
                  >
                    Credit : ChateGPT
                  </h2>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StickFooter />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

export default {
  data() {
    return {
      products: [],
      products_2: [],
      prompts: [],
    };
  },
  components: { ConnectWallet, StickFooter },
  methods: {},
  created() {},
};
</script>
